import { render, staticRenderFns } from "./catalog-list-index.vue?vue&type=template&id=13d18e72&"
import script from "./catalog-list-index.vue?vue&type=script&lang=js&"
export * from "./catalog-list-index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonCardSmall: require('/home/d/didisher2/autovyborg.ru/www/components/skeleton/skeleton-card/skeleton-card-small.vue').default,CatalogIndexSwiper: require('/home/d/didisher2/autovyborg.ru/www/components/catalog/catalog-index-swiper.vue').default})
